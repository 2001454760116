<script setup lang="ts">
// After updating Tairo, we should refactor this because they've improved the card/form wrapping, I believe.

const props = withDefaults(
  defineProps<{
    title?: string
    subTitle?: string
    icon?: string
    isSubmitting: boolean
    showCancelButton?: boolean
    submitLabel?: string
    useGrid?: boolean
    // `md` is a medium/small sized card, the default
    // `lg` is a full width card (so you can size it from the parent)
    size?: 'md' | 'lg'
    // Whether to align the form to the left (instead of the default center)
    alignLeft?: boolean
  }>(),
  {
    title: undefined,
    subTitle: undefined,
    icon: undefined,
    isSubmitting: false,
    showCancelButton: true,
    submitLabel: undefined,
    useGrid: true,
    size: 'md',
    alignLeft: false,
  },
)

// Submit event is emitted by the FormBase's form tag.
const emit = defineEmits<{
  // (e: 'submit'): (e?: Event | undefined) => Promise<Promise<void> | undefined>
  (e: 'cancel'): () => void
}>()
</script>

<template>
  <FormBase :size="size" :align-left="alignLeft">
    <CardMedium
      :icon="props.icon"
      :title="props.title"
      :sub-title="props.subTitle"
      :size="props.size"
    >
      <div
        :class="useGrid ? 'grid grid-cols-12' : 'flex flex-col'"
        class="gap-4"
      >
        <slot />
      </div>
      <slot name="id-errors">
        <ErrorMessage name="id" class="text-sm text-pink-600" />
      </slot>
      <slot name="buttons">
        <div class="mt-4 flex items-center gap-4 pt-4">
          <BaseButton
            v-if="props.showCancelButton"
            class="!h-12 w-full"
            @click="emit('cancel')"
          >
            {{ $t('cancel') }}
          </BaseButton>

          <SubmitButton
            :is-submitting="props.isSubmitting"
            class="!h-12 w-full"
          >
            {{ props.submitLabel || $t('save') }}
          </SubmitButton>
        </div>
      </slot>
    </CardMedium>
  </FormBase>
</template>
